import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'

export const AboutPageTemplate = ({ title, content, contentComponent, bannerImage, subImage }) => {
  const PageContent = contentComponent || Content

  return (
    <div className="about-me">
      <div className="banner-image"
        style={{
          backgroundImage: `url(${
            bannerImage && bannerImage.childImageSharp ? bannerImage.childImageSharp.fluid.src : bannerImage
          })`,
        }}
      >
        <h2 className="title is-size-3 has-text-weight-bold is-bold-light">
          {title}
        </h2>
      </div>
      <section className="section section--gradient">
        <div className="container">
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <div className="content-container">
                <div className="sub-image-container">
                  <img
                    className="sub-image"
                    src={subImage && subImage.childImageSharp ? subImage.childImageSharp.fluid.src : subImage}
                    alt="chamomile tea"
                  />
                </div>
                <PageContent className="content" content={content} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

AboutPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

const AboutPage = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <AboutPageTemplate
        bannerImage={post.frontmatter.bannerImage}
        subImage={post.frontmatter.subImage}
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
      />
    </Layout>
  )
}

AboutPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default AboutPage

export const aboutPageQuery = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        bannerImage {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        subImage {
          childImageSharp {
            fluid(maxWidth: 1000, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
